import React, { forwardRef, useState, useEffect } from "react";

import { Grid, TextField, Paper, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, getProfile } from "../util";
import Toast from "../components/Toast";
import AlertDialog from "../components/AlertDialog";
import MaterialTable from "material-table";

import Swal from "sweetalert2";
import AdaptDialog from "../components/AdaptDialog";
import { tableIcons } from "../components/TableIcons"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
  },
  formRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  fRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '200',
    },
  },

  form_Root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '50%',
    },
  },

  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));



export default function ViewSuppliers() {
  const route = "/v2/inventory/supplier";
  const compName = "Supplier";
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const formFields = [
    {
      id:'bizname',
      label: 'Supplier Name',
      required:true
    },{
      id:'email',
      label: 'Email',
      required:false
    },{
      id:'address',
      label: 'Address',
      required:false
    },{
      id:'contact',
      label: 'Contact',
      required:false
    }
  ]

  const handleDialogClose = event => {
    setIsDialogOpen(false);
  }

  const handleDialogClose2 = event => {
    setIsDialogOpen2(false);
  }

  const handleFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAddNewRow = event => {
    event.preventDefault();
    dataProvider('post', route, formData)
    .then((res) => {
      if(res.data.error === false) {
        Swal.fire({
          text: res.data.message,
          icon: 'success',
          toast: true,
          position: 'bottom-end',
          'timer': 2000,
        })
        loadData();
        reset();
       
      }
    })
    .catch((error) => {
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
    });
  }
  const handleEditRow = event => {
    event.preventDefault();
    dataProvider('put', route, formData)
    .then((res) => {
      if(res.data.error === false) {
        Swal.fire({
          text: res.data.message,
          icon: 'success',
          toast: true,
          position: 'bottom-end',
          'timer': 2000,
        })
        loadData();
        reset();
       
      }
    })
    .catch((error) => {
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
    });
  }

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadData();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  async function loadData() {
    try {
      const mydata = await dataProvider("get", route+'s', []);
      setData(mydata.data.data);
    } catch (error) {
      console.error(error);
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
    }
  }

  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    // setFormData({});
  }




  const handleDeleteRow = (rowData) => {
    return new Promise((resolve, reject) => {
      dataProvider("delete", route, rowData)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          resolve();
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: 'warning',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          reject();
        });
    })
  }
  const view_admin = [
    { title: "ID", field: "id", editable: false},
    {
      title: "Supplier",
      field: "bizname"
    },
    { title: "Contact", field: "contact" },
    { title: "Email", field: "email" },
    { title: "Address", field: "address" },

  ];

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            style={{ fontSize: 14 }}
            title={compName+"s"}
            columns={view_admin}
            icons={tableIcons}
            data={data ? data : null}
            // editable={
            //   profile?.permission >= 5
            //     ? {
            //       onRowUpdate: (newData, oldData) =>
            //         new Promise((resolve, reject) => {
            //           dataProvider("put", route, newData)
            //             .then((res) => {
            //               Swal.fire({
            //                 text: res.data.message,
            //                 icon: 'success',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               loadData();
            //               resolve();
            //             })
            //             .catch((error) => {
            //               Swal.fire({
            //                 text: error.message,
            //                 icon: 'warning',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               reject();
            //             });
            //         }),
            //     }
            //     : null
            // }
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100, 500],
              sorting: true,
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                // console.log(rowData)
                if (rowData.tableData.id % 2 === 0) {
                  // return { backgroundColor: "#fceffc" };
                  return { backgroundColor: "#eaf9f7" };
                }
              },
            }}
            actions={[
              {
                tooltip: "Refresh",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },
              }, {
                tooltip: "Add "+compName,
                icon: tableIcons.Add,
                position: "toolbar",
                onClick: async () => {
                  setIsDialogOpen(true);
                },
              },
              profile?.permission >= 5?           
              (rowData) => ({
                icon: tableIcons.Edit,
                tooltip: "Edit",
                onClick: async (event, rowData) => {
                  console.log(rowData)
                  setFormData(rowData)
                  setIsDialogOpen2(true);
                  }
                }):null,
              (rowData) => ({
                icon: tableIcons.Delete,
                tooltip: "Delete",
                onClick: async (event, rowData) => {
                  await Swal.fire({
                    text: 'Click delete to confirm.',
                    // showDenyButton: true,s
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    // denyButtonText: `Don't save`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDeleteRow(rowData)
                      return true;
                    } else if (result.isDenied) {
                      return false;
                    }
                  })
                }

              })

              
            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}
    
      <AdaptDialog title={"Add "+compName} isOpen={isDialogOpen} onClose={handleDialogClose}>
        <Paper style={{ padding: '2em' }} >
          <form  autoComplete="off" onSubmit={handleAddNewRow}>
          {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

          <Grid container spacing={2}>
            {
              formFields.map(i => 
              <Grid item xs={12} md={6} key={i.id}>
                <TextField 
                  inputProps={{
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />
              </Grid>)
            }
       
            <Grid item xs={12} >
              <div style={{ marginTop: '3em', textAlign: 'right',}}>
                <Button type="submit" style={{margin:5}} color="primary" variant="contained">Save</Button>
                <Button style={{margin:5}}  margin="normal" onClick={handleDialogClose}>Close</Button>
              </div>
            </Grid>
          </Grid>
          {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>

          
      <AdaptDialog title={"Edit "+compName} isOpen={isDialogOpen2} onClose={handleDialogClose2}>
        <Paper style={{ padding: '2em' }} >
          <form  autoComplete="off" onSubmit={handleEditRow}>
          {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

          <Grid container spacing={2}>
            {
              formFields.map(i => 
              <Grid item xs={12} md={6} key={i.id}>
                <TextField value={formData[i.id]} required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />
              </Grid>)
            }
       
            <Grid item xs={12} >
              <div style={{ marginTop: '3em', textAlign: 'right',}}>
                <Button type="submit" style={{margin:5}} color="primary" variant="contained">Save</Button>
                <Button style={{margin:5}}  margin="normal" onClick={handleDialogClose2}>Close</Button>
              </div>
            </Grid>
          </Grid>
          {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>

    </div >
  );
}
