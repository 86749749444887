import React, { useRef, useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import { Paper, Button, Box, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, getProfile, formatter, searchGlobalProduct } from "../util";
import MaterialTable from "material-table";
import TextField from '@mui/material/TextField';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import { Autocomplete } from "@material-ui/lab";
import Swal from "sweetalert2";
import AdaptDialog from "../components/AdaptDialog";
import { tableIcons } from "../components/TableIcons";
import { number } from "prop-types";
import Inventory from "@mui/icons-material/Inventory";
import Grid from '@mui/material/Grid';
import { Alert, Skeleton } from "@mui/material";
var Barcode = require('react-barcode');
var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
  },
  formRoot: {
    "& > *": {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  fRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '200',
    },
  },

  form_Root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      // width: '50%',
    },
  },

  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function ViewConfig() {
  const route = "/configsheet";
  const compName = "Configuration";
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [isDialogOpen_up, setIsDialogOpen_up] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectValue, setSelectValue] = useState("");
  const [formData, setFormData] = useState({});
  var tableRef = useRef();
  const [fetchedData, setFetchedData] = React.useState({});
  const [product, setProduct] = React.useState({});
  const [productSearch, setProductSearch] = React.useState([]);

  const formFields = [
    {
      id: 'title',
      label: 'Title',
      required: true,
      type: 'text',
      disable: true
    }, {
      id: 'description',
      label: 'Description' ,
      required: true,
      type: 'text',
      disable: false
    },
    {
      id: 'amount',
      label: 'Amount',
      required: true,
      type: 'number',
      disable: false
    },
  ]

  const handleDialogClose = event => {
    setIsDialogOpen(false);
  }
  const handleDialogClose2 = event => {
    setIsDialogOpen2(false);
  }
  const handleDialogClose_up = event => {
    setIsDialogOpen_up(false);
  }
  const handleFormDataChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleAddNewRow = event => {
    event.preventDefault();
    if (_.isEmpty(formData)) return false;
    formData.supplier_name = _.find(suppliers, { id: formData.supplier })?.bizname;
    dataProvider('post', route, formData)
      .then((res) => {
        if (res.data.error === false) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          reset();

        }
      })
      .catch((error) => {
      });



  }
  const handleEditRow = event => {
    event.preventDefault();
    if (_.isEmpty(formData)) return false;
   dataProvider('put', route+"/"+formData.id, formData)
      .then((res) => {
        if (res.data.value === true) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          // reset();

        }
      })
      .catch((error) => {
        Swal.fire(
          undefined,
          error.message,
          'warning',
        )
      });
  }
  const handleUpdateRow = event => {
    event.preventDefault();
    // console.log(formData)

    if (_.isEmpty(fetchedData)) return false;
    fetchedData.receival = true;
    // fetchedData.stock = formData.stock;
    setLoading(true)
    dataProvider('put', route, formData)
      .then((res) => {
        setTimeout(() => { setLoading(false) }, 1500)
        if (res.data.error === false) {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
        
          setFetchedData({ ...fetchedData, stock: parseInt(fetchedData.stock) + parseInt(formData.stock) });
          setFormData({ ...formData, stock: null })
          Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
          );


          return true;
        }
      })
      .catch((error) => {
        setTimeout(() => { setLoading(false) }, 1500)
        console.log(error, "errr")
      });
  }

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();

  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }



  async function loadData() {
    tableRef.current.onQueryChange()
  }

  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0].click();
    setFormData({});
  }


  const handleDeleteRow = (rowData) => {
    return new Promise((resolve, reject) => {
      dataProvider("delete", route, rowData)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          loadData();
          resolve();
        })
        .catch((error) => {
          Swal.fire({
            text: error.message,
            icon: 'warning',
            toast: true,
            position: 'bottom-end',
            'timer': 2000,
          })
          reject();
        });
    })
  }
  const view_admin = [
    { title: "ID", field: "id", editable: false },
    { title: "Title", field: "title", editable: false },
  
    { title: "Description", field: "description" },

   {
      title: "Amount", field: "amount",
      render: (rowData) => {
        return rowData.amount !== null?<Chip size="small" label={(rowData.amount.toLocaleString())} color="info" variant="outlined" />:''
      }
    }
  ];




  let tableData = async query => {
    try {
      console.log(query, "QUERY")
      const result = await dataProvider("get", `/configsheet_` +
        `?per_page=${query.pageSize}` +
        `&page=${query.page + 1}` +
        `&orderBy=${query.orderBy?.field}` +
        `&orderDirection=${query.orderDirection}` +
        `&search=${query.search}`, []);

      if (!result) return { data: null, page: null, totalCount: null }
      return {
        data: result.data.data.data,
        page: result.data.data.page - 1,
        totalCount: result.data.data.total,
      };
    } catch (error) {
      console.error(error);
      Swal.fire(
        undefined,
        error.message,
        'warning',
      )
      return [];
    }

  }

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <div style={{padding:10}}>
          <Alert severity="info">Please close and re-open application for changes to take effect</Alert>
          </div>
          <MaterialTable
            tableRef={tableRef}
            //           onSearchChange={(e) =>{
            // console.log(e)
            //           }}
            style={{ fontSize: 14 }}
            title={compName}
            columns={view_admin}
            icons={tableIcons}
            data={tableData ? tableData : null}
            // data={data ? data : null}
            // editable={
            //   profile?.permission >= 5
            //     ? {
            //       onRowUpdate: (newData, oldData) =>
            //         new Promise((resolve, reject) => {
            //           dataProvider("put", route, newData)
            //             .then((res) => {
            //               Swal.fire({
            //                 text: res.data.message,
            //                 icon: 'success',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               loadData();
            //               resolve();
            //             })
            //             .catch((error) => {
            //               Swal.fire({
            //                 text: error.message,
            //                 icon: 'warning',
            //                 toast: true,
            //                 position: 'bottom-end',
            //                 'timer': 2000,
            //               })
            //               reject();
            //             });
            //         }),
            //     }
            //     : null
            // }
            options={{
              pageSize: 15,
              pageSizeOptions: [10, 20, 50, 100, 150, 300],
              sorting: true,
              search: true,
              exportButton: false,
              rowStyle: (rowData) => {
                // console.log(rowData)

                if (rowData.stock <= rowData.low_stock && !(rowData.stock <= 0)) {
                  return { backgroundColor: "#fcecc2" };
                }
                // if (rowData.tableData.id % 2 === 0 && rowData.stock !== 0) {
                //   return { backgroundColor: "#eaf9f7" };
                // }

                if (rowData.stock <= 0) {
                  return { backgroundColor: "#fceffc" };
                }
              },
            }}
            actions={[
           
              profile?.permission >= 5 ?
                (rowData) => ({
                  icon: tableIcons.Edit,
                  tooltip: "Edit",
                  onClick: async (event, rowData) => {
                    setFormData(rowData)
                    setIsDialogOpen2(true);
                  }
                }) : null,

     


            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}


      <AdaptDialog title={"Edit " + compName} isOpen={isDialogOpen2} onClose={handleDialogClose2}>
        <Paper style={{ padding: '2em' }} >
          <form autoComplete="off" onSubmit={handleEditRow}>
            {/* <Box
            className={classes.form_Root}
            justifyContent="center" m={3} p={3}
          > */}

            <Grid container spacing={2}>
              {

                formFields.map(i => {
                  return <Grid item xs={12} md={6} key={i.id}>
                    {i.type === 'select' ? <>
                      <Autocomplete
                        label={i.label} name={i.id}
                        options={i.data}
                        // value={formData.[i.id]}
                        defaultValue={_.find(i.data, { id: formData[i.id] })}
                        getOptionLabel={(option) => option.bizname}
                        // onChange={handleFormDataChange}
                        onChange={(event, newValue) => {
                          // console.log(newValue);
                          setFormData({ ...formData, [i.id]: newValue.id });
                        }}
                        style={{ width: 300 }}
                        renderInput={(params) => <> <TextField {...params} label={i.label} name={i.name} variant="outlined" />
                          {/* <small>{(suppliers2?.filter(s => s.id === formData.[i.id]))[0]?.bizname}</small> */}
                        </>}
                      />
                    </> :
formData[i.id] !== null?
                      <TextField  disabled={i.disable} onKeyPress={e => e.key === 'Enter' && e.preventDefault()} type={i.type} value={formData[i.id]} required={i.required} fullWidth variant="outlined" onChange={handleFormDataChange} label={i.label} name={i.id} />:''
                    }
                  </Grid>
                }
                )
              }
              <Grid item xs={12} >
                <div style={{ marginTop: '3em', textAlign: 'right', }}>
                  <Button type="submit" style={{ margin: 5 }} color="primary" variant="contained">Save</Button>
                  <Button style={{ margin: 5 }} margin="normal" onClick={handleDialogClose2}>Close</Button>
                </div>
              </Grid>
            </Grid>
            {/* </Box> */}
          </form>

        </Paper>
      </AdaptDialog>

 

    </div >
  );
}
