import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Paper } from "@mui/material";
import { AttachMoney, CreditCard, Payment, AccountBalanceWallet, Money } from "@mui/icons-material";

const TransactionSummary = ({ totals }) => {
  const transactionTypes = [
    { label: "Cash", value: totals.cash, icon: <AttachMoney />, color: "#4caf50" },
    { label: "Debit", value: totals.debit_card, icon: <Payment />, color: "#2196f3" },
    { label: "Credit", value: totals.credit_card, icon: <CreditCard />, color: "#ff9800" },
    { label: "Online", value: totals.online, icon: <AccountBalanceWallet />, color: "#9c27b0" },
    { label: "Cheque", value: totals.cheque, icon: <Money />, color: "#666" },
  ];

  return (
    <Paper sx={{ p: 1}}>
     <small
                  style={{ float: "left", fontSize: "0.6em", color: "#00bde9" }}
                >
                  Transactions Today
                </small>
      <Grid container spacing={0} justifyContent="flex-start">
        {transactionTypes.map((item, index) => (
          <Grid item key={index} xs={4} sm={4} md={3}>
            <List dense disablePadding>
              <ListItem sx={{ display: "flex", alignItems: "center", minWidth: 120 }}>
                <ListItemIcon sx={{ color: item.color, padding: 0, minWidth: "auto", marginBottom:2, marginRight:1  }}>{item.icon}</ListItemIcon>
             
                <ListItemText
                  primary={<Typography variant="body1" fontWeight="bold">{item.label}</Typography>}
                  secondary={<Typography variant="body2">${item.value.toLocaleString()}</Typography>}
                />
              </ListItem>
            </List>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default TransactionSummary;
